<template>
  <hub-modal :visible="true" class="milestone-modal" closable @close="$emit('close')">
    <div slot="title">Create milestone</div>
    <form v-if="isReady" autocomplete="off" class="create-milestone-form" @submit.stop.prevent>
      <section class="create-milestone-form-body">
        <div class="form-row">
          <p-multiselect
            :value="templateId"
            label="Template"
            :options="milestones"
            :get-label-callback="source => source.label"
            @input="onTemplateSelected"
          />
        </div>
        <div class="form-row">
          <p-text-field v-model="title" label="Title" autocomplete="off" :test-id="'milestone-title'" />
        </div>
        <div class="form-row">
          <div class="form-row-action-list">
            <p-button type="button" variant="text" color="primary" @click.stop.prevent="setMilestoneDueAt('+1d')">+1d</p-button>
            <p-button type="button" variant="text" color="primary" @click.stop.prevent="setMilestoneDueAt('+1w')">+1w</p-button>
            <p-button type="button" variant="text" color="primary" @click.stop.prevent="setMilestoneDueAt('+1m')">+1m</p-button>
          </div>
          <hub-date v-model="dueAt" label="Due At" />
        </div>
        <div class="form-row">
          <p-button
            v-if="!(assignees.length === 1 && assignees[0] === email)"
            class="claim-button"
            variant="text"
            color="primary"
            @click.stop.prevent="claim"
          >
            assign to me
          </p-button>
          <p-assignees v-model="assignees" label="Assigned to" placeholder="" />
        </div>

        <div v-if="variables.length" class="variables">
          <div class="title">Additional info:</div>
          <div v-for="field of variables.filter(f => f.type === 'people')" :key="field.name" class="form-row">
            <p-assignees v-model="field.enteredValue" :label="field.name" placeholder="" :disabled="false" />
          </div>
          <div v-for="field of variables.filter(f => f.type === 'date')" :key="field.name" class="form-row">
            <hub-date v-model="field.enteredValue" :label="field.name" placeholder="" :disabled="false" />
          </div>
          <div v-for="field of variables.filter(f => f.type === 'number' || f.type === 'string')" :key="field.name" class="form-row">
            <p-text-field
              v-model="field.enteredValue"
              :label="field.name"
              :test-id="field.name"
              autocomplete="off"
              :multiline="true"
              :disabled="false"
            />
          </div>
        </div>
      </section>
    </form>
    <div v-else class="loader-wrapper">
      <hub-icon name="loading" spin></hub-icon>
    </div>
    <section slot="footer" class="create-milestone-form-footer">
      <p-button type="button" @click.prevent="$emit('close')">
        Cancel
      </p-button>
      <p-button type="button" color="primary" :disabled="isInvalid" @click.prevent="submit">
        Create
      </p-button>
    </section>
  </hub-modal>
</template>
<script>
import { mapState } from 'vuex';
import httpClient from '@/utils/httpClient';

import Modal from '@/components/common/Modal';
import Multiselect from '@/components/common/Multiselect';
import Button from '@/components/common/Button';

import TextField from '@/components/common/TextField';

import { format, parse } from 'date-fns';
import DateField from '@/components/common/DateField';
import Assignees from '../workflow-editor/parts/AssigneesWithVariables.vue';
import Icon from '@/components/common/MdIcon';
export default {
  components: {
    'hub-modal': Modal,
    'p-assignees': Assignees,
    'p-multiselect': Multiselect,
    'p-button': Button,
    'hub-date': DateField,
    'p-text-field': TextField,
    'hub-icon': Icon
  },
  props: {
    selected: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      templateId: null,
      title: '',
      dueAt: null,
      assignees: [],
      variables: [],
      isReady: false,
      milestones: []
    };
  },
  computed: {
    ...mapState({
      email: s => s.identity.email
    }),
    isInvalid() {
      return !this.templateId || !this.title;
    }
  },
  async created() {
    try {
      this.isReady = false;
      const milestones = await this.$store.dispatch('milestones/getWorkflowByReferences', this.selected.payload.next.invention.references);
      this.milestones = milestones.map(({ id, title, ...rest }) => {
        return {
          ...rest,
          label: title,
          value: id
        };
      });
      this.isReady = true;

      this.claim();
    } catch (e) {}
  },
  methods: {
    async onTemplateSelected(v) {
      this.templateId = v;
      const milestone = this.milestones.find(m => m === v);
      if (milestone) {
        const promises = [];

        const createEvent = milestone.events.find(e => e.type === 'create');

        if (createEvent) {
          const milelestoneInfoPromise = httpClient.post(`/api/workflows/execute/bind`, {
            template: createEvent.action,
            model: { next: this.selected.payload.next },
            context: {
              inventionId: this.selected.inventionId
            }
          });

          promises.push(milelestoneInfoPromise);
        } else {
          promises.push(Promise.resolve({ title: milestone.label }));
        }

        const variables = milestone.variables.filter(v => !v.source);

        const variablePromise = httpClient.post(`/api/workflows/execute/bind`, {
          template: variables,
          model: { next: this.selected.payload.next },
          context: {
            inventionId: this.selected.inventionId
          }
        });

        promises.push(variablePromise);

        const [milelestoneInfo, form] = await Promise.all(promises);

        this.title = milelestoneInfo.title || milestone.label;
        this.dueAt = milelestoneInfo.dueAt && format(new Date(milelestoneInfo.dueAt), 'MMddyyyy');

        if (milelestoneInfo.assignees && milelestoneInfo.assignees.length) {
          this.assignees = milelestoneInfo.assignees;
        }

        this.variables = form.map(v => {
          return {
            name: v.name,
            property: v.property,
            type: v.type,
            enteredValue: v.type === 'date' && v.value ? format(new Date(v.value), 'MMddyyyy') : v.value
          };
        });
      }
    },
    claim() {
      this.assignees = [this.email];
    },
    setMilestoneDueAt(mod) {
      const value = this.applyDateMod(mod, new Date());
      if (value) {
        this.dueAt = format(value, 'MMddyyyy');
      }
    },

    applyDateMod(mod, value = new Date()) {
      switch (mod) {
        case '+1d':
          const tomorrow = new Date(value);
          tomorrow.setDate(value.getDate() + 1);
          return tomorrow;
        case '+1w':
          const inWeek = new Date(value);
          inWeek.setDate(value.getDate() + 7);
          return inWeek;
        case '+1m':
          const inMonth = new Date(value);
          inMonth.setMonth(value.getMonth() + 1);
          return inMonth;
        default:
          return null;
      }
    },
    async submit() {
      let variables = {};

      const obj = {
        templateId: this.templateId.value,
        title: this.title,
        dueAt: this.dueAt ? parse(this.dueAt, 'MMddyyyy', new Date()) : null,
        assignees: this.assignees,
        inventionId: this.selected.inventionId,
        references: [`FIP:${this.selected.payload.next.fipTaskId}`],
        variables: {
          ...this.variables.reduce((acc, curr) => {
            acc[curr.property] = curr.enteredValue;
            return acc;
          }, variables)
        }
      };

      await this.$store.dispatch('milestones/createInstance', obj);
      this.$emit('created');
    }
  },

  validations() {
    return {
      title: { required },
      templateId: { required }
    };
  }
};
</script>
<style lang="scss" scoped>
.milestone-modal {
  .loader-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  .create-milestone-form {
    padding: 20px;
    height: calc(100% - 40px);
    background: var(--theme-surface);

    .create-milestone-form-body {
      min-height: 0;
      height: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
    }

    .form-row {
      position: relative;
      margin-bottom: 0.5rem;
      &.center {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .claim-button {
        position: absolute;
        right: 0;
        font-size: 0.6rem;
        padding: 0;
        margin: 0;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }

      .form-row-action-list {
        position: absolute;
        right: 0;
        padding: 0;
        margin: 0;

        > button {
          font-size: 0.6rem;
          padding-right: 0;
          padding-left: 0;
          margin-left: 0.5rem;
        }
      }
    }

    .error {
      font-size: 0.8rem;
      color: var(--theme-error);
      text-align: left;
      padding: 0.25rem 0;
      display: none;
      margin: 0;
    }
    &.dirty {
      .error {
        display: block;
      }
    }

    .variables {
      border: 1px solid var(--theme-on-background);
      padding: 15px;
      .title {
        margin-bottom: 20px;
      }
    }
  }
}
.create-milestone-form-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  vertical-align: bottom;
  padding: 0.5rem;
}
</style>
